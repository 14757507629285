<template>
	<div class="about-area about-style-4 rn-section-gap">
		<div class="container">
			<div class="row row--40 align-items-center">
				<div class="col-lg-6">
					<Video :video-data="videoData" column="col-12" />
				</div>
				<div class="col-lg-6 mt_md--40 mt_sm--40">
					<div class="content">
						<div class="inner">
							<h3 class="title">{{video.title}}</h3>
							<ul class="feature-list" v-html="video.introduce">
								<!-- <li>
									<Icon name="check" size="20" icon-class="icon" />
									<div class="title-wrapper">
										<h4 class="title">A good traveler has no fixed plans</h4>
										<p class="text">
											Lorem ipsum dolor consectetur adipiscing do eiusmod tempor
											incididunt labore.
										</p>
									</div>
								</li> -->
								<!-- <li>
									<Icon name="check" size="20" icon-class="icon" />
									<div class="title-wrapper">
										<h4 class="title">A good traveler has no fixed plans</h4>
										<p class="text">
											Lorem ipsum dolor consectetur adipiscing do eiusmod tempor
											incididunt labore.
										</p>
									</div>
								</li> -->
							</ul>
							<!-- <div class="about-btn mt--30">
								<router-link class="btn-default" to="/about">About Our Doob</router-link>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Icon from '../../icon/Icon'
	import Video from "../video/Video";
	import Axios from "axios";
	export default {
		name: 'AboutFour',
		components: {
			Video,
			Icon
		},
		props: {
			image: {}
		},
		data() {
			return {
				video: {},
				videoData: [{
					thumb: '',
					src: '',
				}]
			}
		},

		created() {
			Axios.post(Axios.defaults.baseURL + '/system/video/getVideo', {}).then((res) => {
				console.log(69696969696969)
				console.log(res)
				// 		console.log(res.data.extend.list[0].introduce)
				this.videoData[0].src = res.data.extend.list[0].video
				this.videoData[0].thumb = res.data.extend.list[0].cover
				this.video = res.data.extend.list[0];
			});

		}
	}
</script>