<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in fuwu"
             :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon">
                    <div class="line"/>
                    {{ service.sequence }}
                </div>
                <div class="content">
                    <h4 class="title">
                        <router-link to="/service-details" v-html="service.name"/>
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="service.introduce"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import Axios from "axios";
	
    export default {
        name: 'ServiceFour',
        props: {
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
				fuwu:[],
                serviceList: [
                    {
                        icon: '1',
                        title: 'Awarded Design',
                        description: 'There are many variations variations have.'
                    },
                    {
                        icon: '2',
                        title: 'Design & Creative',
                        description: 'Passages there are many variations have.'
                    },
                    {
                        icon: '3',
                        title: 'App Development',
                        description: 'Variations There variations many Lorem Ipsum.'
                    },
                    {
                        icon: '4',
                        title: 'Product Designer',
                        description: 'Variations There variations of Lorem Ipsum.'
                    },
					
                ]
            }
        },
		created() {
			Axios.post(Axios.defaults.baseURL + '/system/introduce/getIntroduce', {}).then((res) => {
				console.log(res)
				console.log(res.data.extend.list[0].introduce)
		
				this.introduce = res.data.extend.list[0].introduce;
			});
			Axios.post(Axios.defaults.baseURL + '/system/cooperation/getCooperation', {}).then((res) => {
				console.log(res)
				console.log(res.data.extend.list)
			
				this.brandList = res.data.extend.list;
			});
			Axios.post(Axios.defaults.baseURL + '/system/surface/getSurface', {}).then((res) => {
				console.log('服务介绍')
				console.log(res)
				console.log(res.data.extend.list)
				this.fuwu = res.data.extend.list
			});
		},
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>