<template>
    <form class="contact-form-1" @submit="submit">
        <div class="form-group">
            <input
			v-model="name"
                type="text"
                name="fullname"
                placeholder="姓名"
                required
            />
        </div>

        <div class="form-group">
            <input
			v-model="phone"
                type="text"
                name="phone"
                placeholder="联系方式"
                required
            />
        </div>

        <div class="form-group">
            <input
			v-model="address"
                type="text"
                name="phone"
                placeholder="地址"
                required
            />
        </div>


        <!-- <div class="form-group">
            <input
                type="text"
                name="subject"
                placeholder="咨询内容"
                required
            />
        </div> -->

        <div class="form-group">
                <textarea
				v-model="content"
                    name="message"
                    placeholder="咨询内容"
					required
                    >
                </textarea>
        </div>

        <div class="form-group">
            <button class="btn-default btn-large"> 提  交 </button>
        </div>

        <!-- <div class="form-group" v-html="showResult ? result : null"/> -->
    </form>
</template>

<script>
	import Axios from "axios";
    export default {
        name: 'ContactForm',
        data() {
            return {
				name:'',
				phone:'',
				address:'',
				content:'',
                // showResult: false,
                // result: `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`
            }
        },
        methods: {
            submit() {
				console.log(this.name)
				console.log(this.phone)
				console.log(this.address)
				console.log(this.content)
                // this.showResult = true
			
				Axios.get(Axios.defaults.baseURL + '/system/message/saveMessage', {
					params: {
						name:this.name,
						phone:this.phone,
						address:this.address,
						content:this.content,
					}
				}).then((res) => {
					console.log('留言成功')
					alert('提交成功')
					this.name = ''
					this.phone = ''
					this.address = ''
					this.content = ''
					console.log(res)
				});
            }
        }
    }
</script>