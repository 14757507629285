<template>
	<Layout :header-transparency="true" :show-top-header="false">
		<!-- Start Slider Area -->
		<div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
			<VueSlickCarousel v-bind="sliderSetting">
				<div v-for="(slider, index) in sliderData1" :key="index" class="single-slide">
					<div class="height-950 bg-overlay bg_image" :style="{'background-image': `url(${slider.src})`}">
						<!-- <div class="container">
							<div class="row row--30 align-items-center">
								<div class="col-lg-12">
									<div class="inner text-center">
										<h1 class="title" v-html="slider.title" />
										<p class="description" v-html="slider.src" />
										<div class="button-group mt--30">
											<a class="btn-default btn-large round" href="#">
												"slider.src"
											</a>
										</div>
									</div>
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</VueSlickCarousel>
		</div>
		<!-- End Slider Area -->

		<Separator />

		<div class="service-area rn-section-gapTop">
			<div class="container">
				<div class="row">
					<div class="col-lg-6">
						<div class="content" data-aos="fade-up">
							<h3 class="title">助力中小企　共圆中国梦</h3>
						</div>
					</div>
					<div class="col-lg-6">
						<p class="mb--10" data-aos="fade-up" v-html="introduce">

						</p>
					</div>
				</div>
			</div>
		</div>


		<div class="rwt-elements-area rn-section-gap">
			<div class="container">
				<div class="row mb--25">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="共圆中国梦" title="一次合作终身朋友  抱团成长" data-aos="fade-up" />
					</div>
				</div>
				<div class="col-lg-10 offset-lg-1 mt--10">
					<Progressbar :progress-data="progressData1" :progress-style="5"
						column="col-lg-3 col-md-6 col-sm-6 mt--30 col-12" />
				</div>
			</div>
		</div>

		<Separator />

		<div class="rwt-timeline-area rn-section-gap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="Working Process" title="开发流程" data-aos="fade-up" />
					</div>
				</div>
				<div class="row">
					<div class="col-lg-10 offset-lg-1 mt--50">
						<Timeline :timeline-data="timelineData1" :timeline-style="3" />
					</div>
				</div>
			</div>
		</div>

		<Separator />

		<div class="rwt-elements-area rn-section-gap">
			<div class="container-fluid plr--30">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="honor" title="部分荣誉" data-aos="fade-up" />
					</div>
				</div>
				<Portfolio :portfolio-data="portfolioData"
					column="col-lg-3 col-md-6 mt--30 box-grid-layout no-overlay" />
			</div>
		</div>

		<Separator />

		<!-- Start Service Area -->
		<div class="rn-service-area rn-section-gap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" radiusRounded="" subtitle="Services" title="我们能提供的服务"
							data-aos="fade-up" />
					</div>
				</div>
				<ServiceFour service-style="service__style--1 icon-circle-style with-working-process"
					text-align="center" />
			</div>
		</div>
		<!-- End Service Area -->

		<Separator />

		<div class="blog-area rn-section-gap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="Latest News" title="最新动态" description=""
							data-aos="fade-up" />
					</div>
				</div>
				<div class="row row--15">
					<div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30" data-aos="slide-up" data-aos-duration="800"
						:data-aos-delay="100 + index" v-for="(blog, index) in posts" :key="index" v-if="index < 3">
						<BlogPost :blog="blog" />
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../components/common/Layout";
	import Separator from "../../components/elements/separator/Separator";
	import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
	import BlogPost from "../../components/blog/BlogPost";
	import BlogPostMixin from "../../mixins/BlogPostMixin";
	import VueSlickCarousel from "vue-slick-carousel";
	import Portfolio from "../../components/elements/portfolio/Portfolio";
	import Progressbar from "../../components/elements/progressbar/Progressbar";
	import Timeline from "../../components/elements/timeline/Timeline";
	import ServiceFour from "../../components/elements/service/ServiceFour";
	import Axios from "axios";

	export default {
		name: 'Startup',
		components: {
			introduce: '',
			ServiceFour,
			Timeline,
			Progressbar,
			Portfolio,
			BlogPost,
			SectionTitle,
			Separator,
			Layout,
			VueSlickCarousel
		},
		mixins: [BlogPostMixin],
		data() {
			return {
				sliderSetting: {
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false,
					arrows: true,
					responsive: [{
							breakpoint: 800,
							settings: {
								slidesToShow: 1,
							}
						},
						{
							breakpoint: 993,
							settings: {
								slidesToShow: 1,
							}
						},
						{
							breakpoint: 580,
							settings: {
								slidesToShow: 1,
							}
						},
						{
							breakpoint: 481,
							settings: {
								slidesToShow: 1,
							}
						}
					]
				},
				sliderData: [{
						image: 'bg-image-4',
						title: `Startup Your <br/> Business Agency.`,
						description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
					},
					{
						image: 'bg-image-18',
						title: `Startup Your <br/> Creative Agency.`,
						description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
					},
					{
						image: 'bg-image-18',
						title: `Startup Your <br/> Creative Agency.`,
						description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
					}
				],
				sliderData1: [{
						src: ''
					},
					{
						src: ''
					}, {
						src: ''
					}

				],
				// sliderData1: [],
				portfolioData: [{
						id: 1,
						portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
						image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
						title: 'App Development',
						subtitle: 'Branded client',
						date: '30 May 2021',
						client: 'Rainbow Themes',
						category: 'development',
						awards: ['2020 Design beautiful apps Finale Awards'],
						excerpt: 'Design beautiful apps.',
						body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
						btn: {
							buttonText: 'View Projects',
							link: '/'
						},
						largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
						subContent: {
							title: 'Digital Marketing.',
							subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent2: {
							title: 'App Development.',
							subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent3: {
							title: 'Solution.',
							subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						gallery: {
							imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
							imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
							imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
						}
					},
					{
						id: 2,
						portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
						image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
						title: 'Business Development',
						subtitle: 'Awesome portfolio',
						date: '01 March 2021',
						client: 'Rainbow Themes',
						category: 'design',
						awards: ['2020 Design beautiful apps Finale Awards'],
						excerpt: 'Design beautiful apps.',
						body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
						btn: {
							buttonText: 'View Projects',
							link: '/'
						},
						largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
						subContent: {
							title: 'Digital Marketing.',
							subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent2: {
							title: 'App Development.',
							subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent3: {
							title: 'Solution.',
							subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						gallery: {
							imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
							imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
							imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
						}
					},
					{
						id: 3,
						portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
						image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
						title: 'Photoshop Design',
						subtitle: 'Awesome portfolio',
						date: '01 March 2021',
						client: 'Rainbow Themes',
						category: 'art',
						awards: ['2020 Design beautiful apps Finale Awards'],
						excerpt: 'Design beautiful apps.',
						body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
						btn: {
							buttonText: 'View Projects',
							link: '/'
						},
						largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
						subContent: {
							title: 'Digital Marketing.',
							subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent2: {
							title: 'App Development.',
							subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent3: {
							title: 'Solution.',
							subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						gallery: {
							imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
							imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
							imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
						}
					},
					{
						id: 4,
						portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
						image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
						title: 'Native Application',
						subtitle: 'Awesome portfolio',
						date: '01 March 2021',
						client: 'Rainbow Themes',
						category: 'development',
						awards: ['2020 Design beautiful apps Finale Awards'],
						excerpt: 'Design beautiful apps.',
						body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
						btn: {
							buttonText: 'View Projects',
							link: '/'
						},
						largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
						subContent: {
							title: 'Digital Marketing.',
							subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent2: {
							title: 'App Development.',
							subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent3: {
							title: 'Solution.',
							subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						gallery: {
							imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
							imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
							imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
						}
					},
					{
						id: 5,
						portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
						image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
						title: 'Vue.js Development',
						subtitle: 'Awesome portfolio',
						date: '01 March 2021',
						client: 'Rainbow Themes',
						category: 'design',
						awards: ['2020 Design beautiful apps Finale Awards'],
						excerpt: 'Design beautiful apps.',
						body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
						btn: {
							buttonText: 'View Projects',
							link: '/'
						},
						largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
						subContent: {
							title: 'Digital Marketing.',
							subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent2: {
							title: 'App Development.',
							subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent3: {
							title: 'Solution.',
							subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						gallery: {
							imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
							imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
							imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
						}
					},
					{
						id: 6,
						portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
						image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
						title: 'App Installment',
						subtitle: 'Awesome portfolio',
						date: '01 March 2021',
						client: 'Rainbow Themes',
						category: 'design',
						awards: ['2020 Design beautiful apps Finale Awards'],
						excerpt: 'Design beautiful apps.',
						body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
						btn: {
							buttonText: 'View Projects',
							link: '/'
						},
						largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
						subContent: {
							title: 'Digital Marketing.',
							subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent2: {
							title: 'App Development.',
							subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						subContent3: {
							title: 'Solution.',
							subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
						},
						gallery: {
							imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
							imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
							imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
						}
					}
				],
				progressData: [{
						id: 1,
						title: '客户满意度',
						percentage: '8',
						subtitle: '一次合作、终身朋友',
						strokeColor: '#0f0f11',
						strokePath: '#059DFF'
					},
					{
						id: 2,
						title: '覆盖行业',
						percentage: 96,
						subtitle: '行业涉及广',
						strokeColor: '#0f0f11',
						strokePath: '#059DFF'
					},
					{
						id: 3,
						title: "客户满意度",
						percentage: 99,
						subtitle: "一次合作、终身朋友",
						strokeColor: '#0f0f11',
						strokePath: '#059DFF'
					},
					{
						id: 4,
						title: '覆盖行业',
						percentage: 97,
						subtitle: '行业涉及广',
						strokeColor: '#0f0f11',
						strokePath: '#059DFF'
					},
				],
				progressData1: [],
				timelineData: [{
						id: '1',
						title: '前期沟通',
						description: '需求对接',
					},
					{
						id: '2',
						title: '签订合同',
						description: '明确需求和时间',
					},
					{
						id: '3',
						title: '开发阶段',
						description: '根据甲方需求实现业务功能',
					},
					{
						id: '4',
						title: '开发完成',
						description: '根据合同需求交付甲方',
					},
				],
				timelineData1: []

			}
		},
		created() {
			// this.getBannerImg();
			console.log(33333)
			let _this = this
			console.log('index Created')

			Axios.post(Axios.defaults.baseURL + '/system/introduce/getIntroduce', {}).then((res) => {
				console.log(res)
				console.log(res.data.extend.list[0].introduce)

				this.introduce = res.data.extend.list[0].introduce;
			});

			Axios.get(Axios.defaults.baseURL + '/system/classification/getClassification', {

			}).then((res) => {
				console.log("首页荣誉")
				console.log(res)
				console.log(res.data.extend.list)

				_this.portfolioData = res.data.extend.list;

			});
		},
		mounted() {
			this.$nextTick(() => {
				this.getBannerImg();
				this.getDevProcess();
			})

		},
		methods: {
			getBannerImg() {
				console.log("9999999")

				this.$nextTick(() => {
					Axios.post(Axios.defaults.baseURL + '/system/banner/getBanner', {}).then((res) => {
						console.log(res)
						console.log(res.data.extend.list)
						console.log(this.sliderData)

						this.sliderData1 = res.data.extend.list;
						console.log(this.sliderData1)
					});
				})

			},
			getDevProcess() {
				Axios.post(Axios.defaults.baseURL + '/system/development/getDevelopment', {}).then((res) => {
					console.log(res)
					console.log(res.data.extend.list)
					this.timelineData1 = res.data.extend.list
				});

				Axios.post(Axios.defaults.baseURL + '/system/progress/getProgress', {}).then((res) => {
					console.log(res)
					console.log(res.data.extend.list)
					this.progressData1 = res.data.extend.list
				});

			}
		}

	}
</script>